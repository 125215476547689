const dialog = require('./dialog');
const page = require('./page');
const util = require('./util');

const selectedList = [];
let maxItems = 1;
let bliUUID = '';

/**
 * @private
 * @function
 * description Gets a list of bonus products related to a promoted product
 */
function getBonusProducts() {
    const bonusproducts = [];
    for (let i = 0, len = selectedList.length; i < len; i += 1) {
        const p = {
            pid: selectedList[i].pid,
            qty: selectedList[i].qty,
            options: {},
        };
        const bp = selectedList[i];
        if (bp.options) {
            for (let a = 0, alen = bp.options.length; a < alen; a += 1) {
                const opt = bp.options[a];
                p.options = {optionName: opt.name, optionValue: opt.value};
            }
        }
        bonusproducts.push({product: p});
    }
    return {bonusproducts};
}

function selectedItemTemplate(data) {
    let attributes = '';
    Object.keys(data.attributes).forEach((attrID) => {
        const attr = data.attributes[attrID];
        attributes += `<li data-attribute-id="${attrID}">\n`;
        attributes += `<span class="display-name">${attr.displayName}</span>: `;
        attributes += `<span class="display-value">${attr.displayValue}</span>\n`;
        attributes += '</li>';
    });
    attributes += '<li class="item-qty">\n';
    attributes += `<span class="display-name">${Resources.QTY}</span>: `;
    attributes += `<span class="display-value">${data.qty}</span>`;
    return [
        `<li class="selected-bonus-item" data-uuid="${data.uuid}" data-pid="${data.pid}">`,
        `<a class="remove-link" title="${Resources.REMOVE}" href="#">${util.svg('remove-icon')}</a>`,
        `<div class="item-name">${data.name}</div>`,
        '<ul class="item-attributes">',
        attributes,
        '<ul>',
        '<li>',
    ].join('\n');
}

// hide swatches that are not selected or not part of a Product Variation Group
function hideSwatches() {
    $('.bonus-product-item:not([data-producttype="master"]) .swatches li').not('.selected').not('.variation-group-value').hide();
    // prevent unselecting the selected variant
    $('.bonus-product-item .swatches .selected').on('click', () => false);
}

/**
 * @private
 * @function
 * @description Updates the summary page with the selected bonus product
 */
function updateSummary() {
    const $bonusProductList = $('#bonus-product-list');
    if (!selectedList.length) {
        $bonusProductList.find('li.selected-bonus-item').remove();
        $('.add-to-cart-bonus').attr('disabled', 'disabled');
    } else {
        const ulList = $bonusProductList.find('ul.selected-bonus-items').first();
        for (let i = 0, len = selectedList.length; i < len; i += 1) {
            const item = selectedList[i];
            const li = selectedItemTemplate(item);
            $(li).appendTo(ulList);
        }
        $('.add-to-cart-bonus').removeAttr('disabled');
    }

    // get remaining item count
    let sumItems = 0;
    $('.selected-bonus-item').each((index, element) => {
        sumItems += Number($(element).find('.item-qty .display-value').text());
    });
    const remain = maxItems - sumItems;
    if (remain <= 0) {
        $bonusProductList.find('.select-bonus-item').attr('disabled', 'disabled');
    } else {
        $bonusProductList.find('.bonus-product-form:not(.master-product-form) .select-bonus-item').removeAttr('disabled');
        $bonusProductList.find('.quantity-error').removeClass('error-msg').text('');
    }
}

let firstLoad = true;

function initializeGrid() {
    const $bonusProduct = $('#bonus-product-dialog');
    const $bonusProductList = $('#bonus-product-list');
    const bliData = $bonusProductList.data('line-item-detail');
    maxItems = bliData.maxItems;
    bliUUID = bliData.uuid;

    $bonusProductList.on('click', '.bonus-product-item a[href].swatchanchor', (e) => {
        e.preventDefault();
        let url = e.currentTarget.href;
        const $this = $(e.currentTarget);
        url = util.appendParamsToUrl(url, {
            source: 'bonus',
            format: 'ajax',
        });
        $.ajax({
            url,
            success(response) {
                $this.closest('.bonus-product-item').empty().html(response);
                hideSwatches();
            },
        });
    }).on('change', '.input-text', (e) => {
        let sumItems = 0;
        const parent = $(e.currentTarget).parents('.bonus-product-form');
        if (parent.hasClass('master-product-form') || $(e.currentTarget).val() === 0) {
            return;
        }

        $('.selected-bonus-item').each((index, element) => {
            sumItems += Number($(element).find('.item-qty .display-value').text());
        });

        if ($(e.currentTarget).val() <= maxItems - sumItems) {
            parent.find('.select-bonus-item').removeAttr('disabled');
            parent.find('.quantity-error').removeClass('error-msg').text('');
        } else {
            parent.find('.select-bonus-item').attr('disabled', 'disabled');
            parent.find('.quantity-error').addClass('error-msg').text(Resources.BONUS_PRODUCT_TOOMANY);
        }
    }).on('click', '.select-bonus-item', (e) => {
        e.preventDefault();
        let sumItems = 0;
        $('.selected-bonus-item').each((index, element) => {
            sumItems += Number($(element).find('.item-qty .display-value').text());
        });

        if (selectedList.length >= maxItems) {
            $bonusProductList.find('.select-bonus-item').attr('disabled', 'disabled');
            return;
        }

        const form = $(e.currentTarget).closest('.bonus-product-form');
        const detail = $(e.currentTarget).closest('.product-detail');
        const uuid = form.find('input[name="productUUID"]').val();
        const qtyVal = form.find('input[name="Quantity"], select[name="Quantity"]').val();
        const qty = Number.isNaN(qtyVal) ? 1 : (+qtyVal);
        if (qty > maxItems - sumItems) {
            $(e.currentTarget).attr('disabled', 'disabled');
            form.find('.quantity-error').addClass('error-msg').text(Resources.BONUS_PRODUCT_TOOMANY);
            return;
        }

        const product = {
            uuid,
            pid: form.find('input[name="pid"]').val(),
            qty,
            name: detail.find('.product-name').text(),
            attributes: detail.find('.product-variations').data('attributes'),
            options: [],
        };

        const optionSelects = form.find('.product-option');

        optionSelects.each((index, element) => {
            product.options.push({
                name: element.name,
                value: $(element).val(),
                display: $(element).children(':selected').first().html(),
            });
        });
        selectedList.push(product);
        $('#bonus-product-list li').remove('.selected-bonus-item');
        updateSummary();
    }).on('click', '.remove-link', (e) => {
        e.preventDefault();
        const container = $(e.currentTarget).closest('.selected-bonus-item');
        if (!container.data('uuid')) { return; }

        const uuid = container.data('uuid');
        const len = selectedList.length;
        for (let i = 0; i < len; i += 1) {
            if (selectedList[i].uuid === uuid) {
                selectedList.splice(i, 1);
                break;
            }
        }
        $('#bonus-product-list li').remove('.selected-bonus-item');
        updateSummary();
    }).on('click', '.add-to-cart-bonus', (e) => {
        e.preventDefault();
        const url = util.appendParamsToUrl(Urls.addBonusProduct, {bonusDiscountLineItemUUID: bliUUID});
        const bonusProducts = getBonusProducts();
        if (bonusProducts.bonusproducts[0].product.qty > maxItems) {
            bonusProducts.bonusproducts[0].product.qty = maxItems;
        }
        // make the server call
        $.ajax({
            type: 'POST',
            dataType: 'json',
            cache: false,
            contentType: 'application/json',
            url,
            data: JSON.stringify(bonusProducts),
        }).done(() => {
            // success
            if (window.pageContext.ns === 'cart') {
                page.refresh();
            } else {
                page.redirect(Urls.cartShow);
            }
        }).fail((xhr, textStatus) => {
            // failed
            if (textStatus === 'parsererror') {
                window.alert(Resources.BAD_RESPONSE);
            } else {
                window.alert(Resources.SERVER_CONNECTION_ERROR);
            }
        }).always(() => {
            $bonusProduct.dialog('close');
        });
    }).on('click', '#more-bonus-products', (e) => {
        e.preventDefault();
        const {uuid} = $('#bonus-product-list').data().lineItemDetail;

        // get the next page of choice of bonus products
        const lineItemDetail = JSON.parse($('#bonus-product-list').attr('data-line-item-detail'));
        lineItemDetail.pageStart += lineItemDetail.pageSize;
        $('#bonus-product-list').attr('data-line-item-detail', JSON.stringify(lineItemDetail));

        const url = util.appendParamsToUrl(Urls.getBonusProducts, {
            bonusDiscountLineItemUUID: uuid,
            format: 'ajax',
            lazyLoad: 'true',
            pageStart: lineItemDetail.pageStart,
            pageSize: $('#bonus-product-list').data().lineItemDetail.pageSize,
            bonusProductsTotal: $('#bonus-product-list').data().lineItemDetail.bpTotal,
        });

        $.ajax({
            type: 'GET',
            cache: false,
            contentType: 'application/json',
            url,
        }).done((data) => {
            // add the new page to DOM and remove 'More' link if it is the last page of results
            $('#more-bonus-products').before(data);
            if ((lineItemDetail.pageStart + lineItemDetail.pageSize) >= $('#bonus-product-list').data().lineItemDetail.bpTotal) {
                $('#more-bonus-products').remove();
            }
        }).fail((xhr, textStatus) => {
            if (textStatus === 'parsererror') {
                window.alert(Resources.BAD_RESPONSE);
            } else {
                window.alert(Resources.SERVER_CONNECTION_ERROR);
            }
        });
    });
    if (firstLoad) {
        const cartItems = $bonusProductList.find('.selected-bonus-item');
        cartItems.each((index, element) => {
            const selectedItem = `#item-${$(element).data('uuid')}`;
            const selectedItemQty = $(element).find('.selected-qty').text();
            $(element).remove();
            $(selectedItem).find('.bonus-product-form .input-text').val(selectedItemQty);
            $(selectedItem).find('.bonus-product-form .select-bonus-item').trigger('click');
        });
        firstLoad = false;
    }
    let sumItems = 0;
    $('.selected-bonus-item').each((index, element) => {
        sumItems += Number($(element).find('.item-qty .display-value').text());
    });

    if (sumItems >= maxItems) {
        $bonusProductList.find('.select-bonus-item').attr('disabled', 'disabled');
    }
}

const bonusProductsView = {
    /**
     * @function
     * @description Open the list of bonus products selection dialog
     */
    show(url) {
        const $bonusProduct = $('#bonus-product-dialog');
        // create the dialog
        dialog.open({
            target: $bonusProduct,
            url,
            options: {
                width: 950,
                title: Resources.BONUS_PRODUCTS,
                dialogClass: 'bonus-dialog',
            },
            callback() {
                initializeGrid();
                hideSwatches();
            },
        });
    },
};

module.exports = bonusProductsView;
